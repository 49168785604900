<script setup lang="ts">
import { useAppConfiguration } from '~/stores/appConfiguration'
const { $auth, $auth0, $datadog } = useNuxtApp()
const router = useRouter()

const appConfig = useAppConfiguration()
const { isAuthenticated, logout } = $auth0()

const onSignOut = async () => {
  try {
    if (isAuthenticated.value)
      await logout({
        logoutParams: {
          returnTo: `${location.origin}/auth/login`,
        },
      })
    await $auth.tokenInvalidate()
  } catch (error) {
    $datadog.addError(error)
  }

  $datadog.clearUser()
  router.push('/signin')
}

onMounted(() => {
  $auth.tokenVerify()
})
</script>

<template>
  <div class="min-h-screen">
    <AppToolbar class="sticky top-0">
      <div
        v-if="$auth.userId && !appConfig.maintenance"
        class="flex h-full items-center"
      >
        <NuxtLink to="/" class="btn btn-outlined h-8">Dashboard</NuxtLink>
      </div>
      <template #user-menu>
        <AppUserMenu v-if="$auth.userId">
          <ul class="flex flex-col p-2">
            <li>
              <button class="user-menu-link" @click="onSignOut()">
                Log out
              </button>
            </li>
          </ul>
        </AppUserMenu>
        <NuxtLink v-else to="/signin" class="btn btn-filled h-8">
          Sign in
        </NuxtLink>
      </template>
    </AppToolbar>
    <slot />
  </div>
</template>

<style scoped>
.user-menu-link {
  @apply inline-flex w-full rounded-lg px-4 py-2 text-left text-sm font-light hover:bg-neutral-100 hover:text-neutral-800;
}
</style>
